import React, { useEffect } from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import { H2 } from "../shared/Typography/Typography"
import { useTranslation } from "gatsby-plugin-react-i18next"
import StudioInfoPost from "./StudioInfoPost/StudioInfoPost"
import { infoPostList } from "./StudioInfoPost/infoPostList"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledInnerYellowBox = styled(H2)`
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: ${({ theme }) => `${theme.spaces[64]} ${theme.spaces[16]}`};
  margin: 0;
  @media ${device.lg} {
    padding: ${({ theme }) => theme.spaces[56]};
  }
`
const StyledStudioInfoPosts = styled.div``

const StyledSectionWrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`

export default function StudioInfo ({ ...props }) {
  const { t } = useTranslation()
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])
  return (
    <StyledSectionWrapper {...props}>
      <StyledInnerYellowBox data-aos="fade-in">
        {t("Obsługujemy profesjonalny")}
      </StyledInnerYellowBox>
      <StyledStudioInfoPosts>
        {infoPostList.map((el, key) => (
          <StudioInfoPost
            imgUrl={el.imgUrl}
            imgAlt={el.imgAlt}
            title={el.title}
            listElements={el.listElements}
            key={key}
            data-aos="fade-in"
          ></StudioInfoPost>
        ))}
      </StyledStudioInfoPosts>
    </StyledSectionWrapper>
  )
}
