import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import StudioHeader from "../components/Equipment/StudioHeader"
import YellowBox from "../components/shared/YellowBox/YellowBox"
import styled from "styled-components"
import { device } from "../components/shared/styles/breakpoints"
import { graphql } from "gatsby"
import StudioInfo from "../components/Equipment/StudioInfo"
import FrenzySection from "../components/Equipment/FrenzySection"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledEqWrapper = styled.div``

const StyledYellowBox = styled(YellowBox)`
  max-width: 980px;
  @media ${device.lg} {
    margin: ${({ theme }) => `0 auto -${theme.spaces[96]} `};
  }
`

export default function EquipmentPage () {
  const { t } = useTranslation()
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])
  return (
    <Layout>
      <Seo title={t("Sprzęt")} />
      <StyledEqWrapper>
        <StudioHeader />
        <StudioInfo />
        <FrenzySection data-aos="fade-in" />
        <StyledYellowBox data-aos="fade-in" />
      </StyledEqWrapper>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
