import React from "react"
import styled from "styled-components"
import { device } from "../../shared/styles/breakpoints"
import { H4 } from "../../shared/Typography/Typography"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Bullet from "../../../images/equipment/bullet.png"

const StyledStudioInfoPost = styled.div``
const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  height: 280px;
`
const StyledSmallYellowBox = styled(H4)`
  background-color: ${({ theme }) => theme.colors.yellow};
  margin: ${({ theme }) => `-${theme.spaces[48]} auto 0 `};
  padding: ${({ theme }) =>
    `${theme.spaces[16]} 0  ${theme.spaces[16]} ${theme.spaces[32]} `};
  max-width: 343px;
  box-sizing: border-box;
  position: relative;
  @media ${device.sm} {
    max-width: 479px;
  }
  @media ${device.lg} {
    margin: ${({ theme }) => `-${theme.spaces[48]} 0 0 `};
  }
`
const StyledList = styled.ul`
  list-style-image: url(${Bullet});
  padding-left: ${({ theme }) => `${theme.spaces[40]}`};
  margin-bottom: ${({ theme }) => `${theme.spaces[64]}`};
  @media ${device.xl} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: ${({ theme }) => `${theme.spaces[24]}`};
  }
`
const StyledListItem = styled.li`
  padding: ${({ theme }) =>
    `0 ${theme.spaces[100]} ${theme.spaces[16]} ${theme.spaces[16]}`};
  line-height: ${({ theme }) => theme.lineHeights[22]};
`
const StyledListWrapper = styled.div``
const StyledImageWrapper = styled.div`
  margin: ${({ theme }) => `0 auto ${theme.spaces[40]}`};
  position: relative;
`
export default function StudioInfoPost({
  title,
  imgUrl,
  imgAlt,
  listElements,
  ...props
}) {
  const { t } = useTranslation()
  return (
    <StyledStudioInfoPost {...props}>
      <StyledImageWrapper>
        <StyledMiniature src={imgUrl} alt={imgAlt}></StyledMiniature>
        <StyledSmallYellowBox>{t(title)}</StyledSmallYellowBox>
      </StyledImageWrapper>
      <StyledListWrapper>
        <StyledList>
          {listElements.map((el, key) => (
            <StyledListItem key={key}>{t(el)}</StyledListItem>
          ))}
        </StyledList>
      </StyledListWrapper>
    </StyledStudioInfoPost>
  )
}
