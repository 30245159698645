import React from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import { H3, ParagraphRegular } from "../shared/Typography/Typography"
import { ButtonInternalLink } from "../shared/Link/Link"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Logo from "../../images/logo-white.svg"
import Background from "../../images/equipment/frenzyTeam.png"

const StyledFrenzyWrapper = styled.div`
  max-width: 1180px;
  margin: ${({ theme }) => `0 auto  ${theme.spaces[64]}`};
`
const StyledImageWrapper = styled.div`
  position: relative;
`
const StyledYellowSection = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: ${({ theme }) =>
    ` ${theme.spaces[120]} ${theme.spaces[16]} ${theme.spaces[64]}`};
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  @media ${device.md} {
    padding: ${({ theme }) =>
    ` ${theme.spaces[56]} ${theme.spaces[182]} ${theme.spaces[56]} ${theme.spaces[56]}`};
  }
  @media ${device.lg} {
    max-width: 580px;
    padding: ${({ theme }) => theme.spaces[56]};
    margin-top: ${({ theme }) => `-${theme.spaces[48]}`};
  }
`

const StyledLogo = styled.img`
  padding: 0 15px;
  box-sizing: border-box;
  width: 163px;
  height: 163px;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.black};
  object-fit: contain;

  @media ${device.lg} {
    width: 180px;
    height: 180px;
  }
`
const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  min-height: 410px;
`
const StyledYellowTitle = styled(H3)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`

const StyledYellowText = styled(ParagraphRegular)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[24]}`};
`

const StyledButton = styled(ButtonInternalLink)`
  display: inline-block;
`

export default function FrenzySection({ ...props }) {
  const { t } = useTranslation()
  return (
    <StyledFrenzyWrapper {...props}>
      <StyledImageWrapper>
        <StyledMiniature src={Background} alt="Frenzy Team"></StyledMiniature>
        <StyledLogo src={Logo} alt="Frenzy Logo"></StyledLogo>
      </StyledImageWrapper>
      <StyledYellowSection>
        <StyledYellowTitle>
          {t("Tworzone przez pasjonatów dla graczy")}
        </StyledYellowTitle>
        <StyledYellowText>{t("Napędza nas pasja")}</StyledYellowText>
        <StyledButton sufixIcon color="secondary" to="/about-us">
          {t("Więcej o nas")}
        </StyledButton>
      </StyledYellowSection>
    </StyledFrenzyWrapper>
  )
}
