import React from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import {
  H2,
  ParagraphRegular,
  Disclaimer,
} from "../shared/Typography/Typography"
import Miniature from "../../images/equipment/equipmentHeader.png"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledHeaderTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`
const StyledHeaderText = styled(ParagraphRegular)`
  margin: 0 auto;
  @media ${device.lg} {
    max-width: 520px;
  }
`
const StyledDisclaimer = styled(Disclaimer)`
  margin: 0 auto;
  text-align: center;
  width: 100%;
`
const StyledDisclaimerWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: ${({ theme }) => theme.spaces[16]};
  padding: ${({ theme }) => `0 ${theme.spaces[16]} `};
`
const StyledTextWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spaces[64]} ${theme.spaces[16]}  `};
  max-width: 520px;
  @media ${device.sm} {
    text-align: center;
    margin: ${({ theme }) => `${theme.spaces[64]} auto  `};
  }
  @media ${device.lg} {
    max-width: 710px;
    margin: ${({ theme }) => `${theme.spaces[64]} auto  ${theme.spaces[102]}`};
  }
`
const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  min-height: 410px;
`
const StyledHeaderWrapper = styled.div`
  max-width: 1180px;
  margin: ${({ theme }) => `0 auto ${theme.spaces[64]} `};
`

export default function StudioHeader() {
  const { t } = useTranslation()
  return (
    <StyledHeaderWrapper>
      <StyledTextWrapper>
        <StyledHeaderTitle>Studio 500m²</StyledHeaderTitle>
        <StyledHeaderText>{t("Studio znajduje się")}</StyledHeaderText>
      </StyledTextWrapper>
      <StyledMiniature src={Miniature}></StyledMiniature>
      <StyledDisclaimerWrapper>
        <StyledDisclaimer>
          {t("Naszpikowane nowoczesną technologią")}
        </StyledDisclaimer>
      </StyledDisclaimerWrapper>
    </StyledHeaderWrapper>
  )
}
