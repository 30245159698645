import Miniature1 from "../../../images/equipment/postImage1.png"
import Miniature2 from "../../../images/equipment/postImage2.png"
import Miniature3 from "../../../images/equipment/postImage3.png"

export const infoPostList = [
  {
    title: "Powierzchnia 500 m2 – wys. 7m",
    imgUrl: Miniature1,
    imgAlt: "studio",
    listElements: [
      "polerowana podłoga",
      "pomosty techniczne",
      "punkty przyłączeniowe",
      "emisyjne przyłącze",
      "bezpośrednia komunikacja",
      "internet WIFI",
      "cicha klimatyzacja",
    ],
  },
  {
    title: "Najważniejsze elementy technologicznego wyposażenie studia:",
    imgUrl: Miniature2,
    imgAlt: "studio",
    listElements: [
      "reżyserka z czterema torami kamerowymi",
      "system 24 kamer automatycznych",
      "zespół ścian wizyjnych",
      "system graficzny Xpression - oprawa graficzna",
      "system powtórkowy Mira",
      "system obsługi oświetlenia GrandMa",
      "system obsługi multimediów D3",
      "ramię/wysięgnik kamerowy MovieBird",
      "towercam 5m",
      "slider automatyczny 4m",
      "system komputerowy do rozgrywek esportowych",
    ],
  },
  {
    title: "Podstawowe dane dotyczące zasilania elektrycznego:",
    imgUrl: Miniature3,
    imgAlt: "studio",
    listElements: [
      "studio zasilane",
      "w zakresie odbiorów technologicznych niegwarantowanych – moc maksymalna 130 kW",
      "w zakresie odbiorów",
      "w zakresie odbiorów administracyjnych – moc maksymalna 95 kW",
      "stacja ST-3",
    ],
  },
]
